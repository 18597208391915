var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: { title: "平台", show: _vm.open, isForm: _vm.isForm },
      on: {
        show: _vm.show,
        closed: function ($event) {
          return _vm.$emit("closed")
        },
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c("div", {}, [_vm._v("详情")]),
      _c("div", { attrs: { slot: "form" }, slot: "form" }, [_vm._v("编辑")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }