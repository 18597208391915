<template>
  <drawer-layout 
    title="平台" 
    :show="open" 
    :isForm="isForm"
    @show="show" 
    @closed="$emit('closed')" 
    @save="save"
    @edit="edit"
  >
    <div class="">详情</div>
    <div slot="form">编辑</div>
  </drawer-layout>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      
    }
  },
  methods:{
    show() {
      console.log('显示...');
    },
    save() {
      console.log('保存...');
    },
    edit() {
      console.log('编辑...');
    },
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>